.btn-multiple-state {
  .spinner {
    width: 36px;
    text-align: center;
  }

  .spinner > span {
    width: 6px;
    height: 6px;
    background-color: $button-text-color;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
    animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
