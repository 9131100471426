.w-10 {
  width: 10% !important;
}

.w-90 {
  width: 90% !important;
}

.w-12 {
  width: 12% !important;
}

.w-88 {
  width: 88% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-xs-100 {
  @include respond-below(sm) {
    width: 100% !important;
  }
}

.w-sm-100 {
  @include respond-below(md) {
    width: 100% !important;
  }
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.depth-1 {
  @include depth(1);
}

.depth-2 {
  @include depth(2);
}

.min-width-zero {
  min-width: 0;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.float-none-xs {
  @include respond-below(xs) {
    float: initial !important;
  }
}
