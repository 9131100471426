.app-menu {
  z-index: 1;
  @include depth(1);
  width: $app-menu-width;
  float: right;
  background: $foreground-color;
  transition: transform $menu-collapse-time;
  height: calc(100% - #{$navbar-height});
  position: fixed;
  right: 0;
  top: $navbar-height;

  @include respond-below(xl) {
    top: $navbar-height-lg;
    height: calc(100% - #{$navbar-height-lg});
  }

  @include respond-below(lg) {
    top: $navbar-height-md;
    transform: translateX($app-menu-width);
    height: calc(100% - #{$navbar-height-md});
  }

  @include respond-below(sm) {
    top: $navbar-height-xs;
    height: calc(100% - #{$navbar-height-xs});
  }

  .scrollbar-container {
    margin-right: unset;
    padding-right: unset;

    .ps__rail-y {
      right: 2px !important;
    }
  }

  &.shown {
    transform: translateX(0);
  }

  .app-menu-button {
    cursor: pointer;
    position: absolute;
    left: -28px;
    background: $foreground-color;
    top: $main-margin - 15;
    padding: 12px 8px 12px 5px;
    border-radius: 0.2rem;
    color: $secondary-color !important;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 15px;
    line-height: 0;

    @include respond-below(xl) {
      top: $main-margin-lg - 14;
    }

    @include respond-below(lg) {
      top: $main-margin-md - 13;
    }

    @include respond-below(sm) {
      top: $main-margin-xs - 2;
    }
  }

  ul {
    li {
      margin-bottom: 5px;

      a {
        font-size: 13px;
        display: block;
        padding: 3px 0;

        &:hover i {
          color: $theme-color-1;
        }
      }

      i {
        font-size: 1.2em;
        margin-right: 10px;
        color: $secondary-color;
        vertical-align: unset;

        @include respond-below(sm) {
          font-size: 20px;
        }
      }

      &.active i,
      &.active a {
        color: $theme-color-1;
      }
    }
  }
}

.app-row {
  padding-right: $app-menu-width;

  @include respond-below(lg) {
    padding-right: 0;
  }
}
