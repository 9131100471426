@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700');
@import './_01_mixins.scss';
@import './_02_variables';
@import './_03_core.scss';
@import './_04_plugins.scss';
@import './_05_views.scss';

/** your custom css code **/
.fixed-background {
    background: url('/assets/img/login/gasoline-bg.jpg') no-repeat center center
      fixed;
}
.auth-card .image-side {
    background: url('/assets/img/login/gasoline-fr3.jpg') no-repeat top;    
}
.auth-card {
    opacity: 0.99;
}
.centered {
    text-align: center;
}
.float-r {
    float: right;
}
.form-actions {
    cursor: pointer;
    font-size: 1.60rem;
}
.navbar {
    height: 70px;
    padding: 1rem 0;
}
.sidebar {
    height: calc(100% - 70px);
    padding-top: 70px;
}
.sidebar .main-menu {
    height: calc(100% - 70px);
    width: 110px;
}
main {
    margin: 110px 60px 40px 170px;
}
.r-table tr td, .r-table tr th {    
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
}
.table-textmid tr td, .table-textmid tr th {
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    vertical-align: middle;
}
.listing-top-options {
    margin-top: -15px;
}
.open-hours {
    min-width: 2.5rem;
    padding: 0.5rem 0.4rem;
}
/* 
html {
    background-color: $theme-color-1 !important;
}
*/

body.active-modal {
    overflow-y: hidden;
}

.in-modal, .modal-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.modal-overlay {
    background: rgba(49,49,49,0.8);
}
.in-modal-content {
    position: absolute;
    top: 30%;
    left: 20%;
    line-height: 1.4;
    background: #f1f1f1;
    padding: 1.5rem;
    border-radius: 3px;
    width: 70%;
    max-height: 60vh;
    overflow-y: auto;
}

.in-modal-content-bigger {
    position: absolute;
    top: 15%;
    left: 20%;
    line-height: 1.4;
    background: #f1f1f1;
    padding: 1.5rem;
    border-radius: 3px;
    width: 70%;
    max-height: 75vh;
    overflow-y: auto;
}

.sign-canvas {
    border: 1px solid gray;
    margin: 5px;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    color:coral;
}

#partner-switch {
    color: #00FFF6!important;    
    border-color: #00FFF6!important;
}

.img-top-button {
    padding: 0.5rem;
    right: 0;
}

.listing-image {
    width: 50px;
    border-radius: 10px;
}

.listing-row {
    vertical-align: middle !important;
}